import React from 'react';
import { ChevronDown } from 'react-feather';
import { useSelect } from 'downshift';
import clsx from 'clsx';

interface Props {
  items: {
    title: string | JSX.Element;
    value: string | JSX.Element;
    onClick?: () => void;
  }[];
  defaultSelectedItem: {
    title: string | JSX.Element;
    value: string | JSX.Element;
    onClick?: () => void;
  };
  label?: string;
  placeHolder?: string;
  handleSelectedItemchange: (item: any) => void;
  onClick?: (changes: any) => void;
  titleFormat?: (param: any) => any;
  variant?: 'transparent' | 'white' | 'outline';
  isBlock?: boolean;
  menuAlign?: 'right' | 'left';
  menuWidthClass?: string;
}

const DropdownSelect = (props: Props) => {
  const {
    items,
    defaultSelectedItem,
    label,
    placeHolder,
    handleSelectedItemchange = () => {},
    titleFormat = title => title,
    variant = 'transparent',
    isBlock = false,
    menuAlign = 'right',
    menuWidthClass,
    onClick = () => {},
  } = props;

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items,
    defaultSelectedItem,
    onSelectedItemChange: handleSelectedItemchange,
    onIsOpenChange: onClick,
  });

  return (
    <div className={clsx('relative', isBlock ? 'w-full' : 'w-max')}>
      <div
        data-testid="drop-down-button-wrapper"
        className={clsx(
          'flex justify-between px-4 py-2.5 cursor-pointer rounded items-center',
          variant === 'white' && 'bg-white hover:bg-blue-100',
          variant === 'outline' &&
            'bg-white hover:bg-blue-100  border border-neutral-400',
        )}
        {...getToggleButtonProps()}
      >
        <div className="flex flex-col gap-1">
          {label && <span className="text-xs text-neutral-500">{label}</span>}
          <p className="text-sm font-semibold">
            {(selectedItem && titleFormat(selectedItem.title)) || placeHolder}
          </p>
        </div>
        <ChevronDown className="ml-2" size={20} />
      </div>
      <ul
        className={clsx(
          'absolute  bg-white mt-2 z-10 rounded outline-0 space-y-1 shadow max-h-[400px] overflow-y-auto',
          isOpen ? 'p-1' : 'p-0',
          menuAlign === 'right' ? 'right-0' : 'left-0',
          menuWidthClass || 'w-fit',
        )}
        {...getMenuProps()}
      >
        {isOpen &&
          items.map((item, index) => {
            const isSelected =
              selectedItem && selectedItem.value === item.value;
            return (
              <li
                key={`${item.title}`}
                isSelected={isSelected}
                data-testid={`menu-option-${item.title}`}
                className={clsx(
                  'px-3 py-2.5 cursor-pointer rounded ',
                  isSelected ? 'bg-neutral-700' : 'hover:bg-blue-100',
                )}
                {...getItemProps({ item, index })}
              >
                <p className={clsx(isSelected && 'text-white')}>{item.title}</p>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default DropdownSelect;
