import styled from 'styled-components';
import { media } from 'theme';
import TextareaAutosize from 'react-textarea-autosize';

export const RatingMessage = styled.div`
  margin-top: 14px;
  font-size: 14px;
  font-style: italic;
`;

export const ReviewDescription = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.neutral5};
  margin-top: 3px;
`;

export const CustomRatingSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomRatingTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

export const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;

  ${media.largeAndDown`
    justify-content: space-between;
    width: 300px;
  `}
`;

export const NotSureButton = styled.div`
  border-radius: 4px;
  border: 2px solid
    ${props =>
      props.isSelected
        ? props.theme.colors.green5
        : props.theme.colors.neutral4};
  background-color: ${props =>
    props.isSelected ? props.theme.colors.green5 : 'white'};
  font-weight: bold;
  padding: 5px 9px;
  margin-left: 70px;
  color: ${props => (props.isSelected ? 'white' : props.theme.colors.neutral4)};
  cursor: pointer;

  ${media.largeAndDown`
    margin-left: 0px;
    margin-right: 10px
  `}
`;

export const RatingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  height: 80px;
`;

export const RatingTitle = styled.h4`
  margin-bottom: 30px;
  margin-top: 10px;
`;
export const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: ${props => props.theme.colors.neutral4};
`;

export const LabelTag = styled.div`
  padding: 4px 10px;
  background: ${props => (props.isActive ? 'white' : props.theme.colors.blue3)};
  border: 1.5px solid ${props => props.theme.colors.blue3};
  border-radius: 16px;
  cursor: pointer;
  font-size: 14px;
  color: ${props => (props.isActive ? props.theme.colors.blue3 : 'white')};
  margin-right: 12px;
  margin-bottom: 4px;
  width: fit-content;

  &:active {
    color: ${props => props.theme.colors.blue3};
    background: white;
  }
`;

export const LabelSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;

export const FilterButton = styled.div`
  display: flex;
  flext-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.neutral2};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 14px;
  width: 340px;

  ${media.largeAndDown`
    width: 100%;
`}
`;

export const SortOptionText = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

export const TextArea = styled(TextareaAutosize)`
  width: 100%;
  height: 100%;
  outline: none;
  font-family: Open Sans;
  min-height: 90px;
  border: 1px solid ${props => props.theme.colors.neutral4};
  border-radius: 4px;
  padding: 18px 11px;
  border-bottom: ${props => (props.showError ? '2px solid red' : 'normal')};

  ::placeholder {
    color: ${props => props.theme.colors.neutral5};
  }

  ${media.largeAndDown`
    font-size: 16px;
  `}
`;

export const FormError = styled.div`
  color: #e75f6d;
  margin-top: 5px;
  font-size: 12px;
  padding: 0 8px;
  a {
    color: ${props => props.theme.colors.neutral7}; !important;
    font-weight: bold;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  ${media.largeAndDown`
    margin-bottom: 100px;
  `}
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorText = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: ${props => props.theme.colors.neutral5};
`;
