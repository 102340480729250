import React from 'react';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import Linkify from 'react-linkify';
import lowerFirst from 'lodash/lowerFirst';
import truncate from 'lodash/truncate';
import clsx from 'clsx';
import { Anchor } from './styles';

const Link = (props: { children: React.ReactNode; href: string }) => {
  return (
    <Anchor
      href={lowerFirst(props.href)}
      rel="ugc nofollow noopener noreferrer"
      target="_blank"
    >
      {props.children}
    </Anchor>
  );
};

const VirtualHtml = (props: { children: React.ReactNode }) => {
  const style = { textDecoration: 'underline' };
  return React.createElement('span', { style }, props.children);
};

const addUnderline = (underlinedStr: string, underlinedStringArr: string[]) => {
  if (underlinedStringArr && underlinedStringArr.length > 0) {
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x < underlinedStringArr.length; x++) {
      const withUnderlineTag = `<span>${underlinedStringArr[x].replace(
        /\+{2}(.*?)\+{2}/g,
        '$1',
      )}</span>`;
      underlinedStr = underlinedStr.replace(
        underlinedStringArr[x],
        withUnderlineTag,
      );
    }
  }

  return underlinedStr;
};

const Markdown = (props: { source: string; extendedClass?: string }) => {
  const { source, extendedClass } = props;

  // Adding underline to older format of text we had.
  // Note that we does not support underlin in the future.
  const underlinedStringArr = source.match(/\+{2}(.*?)\+{2}/g);
  const underlinedStr =
    underlinedStringArr && underlinedStringArr.length > 0
      ? addUnderline(source, underlinedStringArr)
      : source;

  const formattedStr = underlinedStr
    .replace(/\\/g, '') // We are not supporting backslash because of URL
    .replace(/%E2%80%8B/g, ''); // Remove Zero Width Space from URL

  const linkProperties = {
    target: '_blank',
    rel: 'nofollow noopener noreferrer',
  };

  return (
    <div className={clsx('markdown-body', extendedClass)}>
      <ReactMarkdown
        renderers={{
          link: Link,
          virtualHtml: VirtualHtml,
          paragraph: paragraphProps => {
            return (
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => {
                  // to remove zero width space from link
                  const formattedLink = decoratedHref.replace(/\u200B/g, '');
                  return (
                    <a
                      target="blank"
                      href={lowerFirst(formattedLink)}
                      key={key}
                    >
                      {truncate(lowerFirst(decoratedText), { length: 50 })}
                    </a>
                  );
                }}
                properties={linkProperties}
              >
                <p>{paragraphProps.children}</p>
              </Linkify>
            );
          },
          image: ({ node, ...ImageProps }) => (
            <img {...ImageProps} loading="lazy" alt={ImageProps.alt} />
          ),
        }}
        source={formattedStr}
        escapeHtml={false}
        plugins={[breaks]}
      />
    </div>
  );
};

export default Markdown;
