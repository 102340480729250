export const REVIEW_FORM_PLACEHOLDER = [
  {
    uid: 'credit-cards',
    description:
      `Your review helps other users decide which Credit Card(s) to choose. \n \n` +
      `Tell us about your experience: application process, customer service, cashback or miles rates, & any pros and cons?`,
  },
  {
    uid: 'online-brokerages',
    description:
      `Your review helps other users decide which Online Brokerage(s) to choose. \n \n` +
      `Tell us about your experience: application process, customer service, user-friendliness of platform, brokerage charges?`,
  },
  {
    uid: 'robo-advisors',
    description:
      `Your review helps other users decide which Robo-Advisor(s) to choose. \n \n` +
      `Tell us about your experience: application process, portfolio returns, customer service, management fee?`,
  },
  {
    uid: 'travel-insurance',
    description:
      `Your review helps other users decide which Travel Insurance(s) to choose. \n \n` +
      `Tell us about your experience: application process, customer service, claiming process, value-for-money?`,
  },
  {
    uid: 'sim-only-mobile-plans',
    description:
      `Your review helps other users decide which Telco Mobile Plan(s) to choose. \n \n` +
      `Tell us about your experience: application process, network connection, customer service, value-for-money?`,
  },
  {
    uid: 'savings-accounts',
    description:
      `Your review helps other users decide which Savings Account(s) to choose. \n \n` +
      `Tell us about your experience: application process, customer service, interest rates, & any pros and cons?`,
  },
  {
    uid: 'p2p-lending',
    description:
      `Your review helps other users decide which P2P Lending Platform(s) to choose. \n \n` +
      `Tell us about your experience: application process, customer service, default rate & funds withdrawal experience?`,
  },
  {
    uid: 'investment-courses',
    description:
      `Your review helps other users decide which Investment Course(s) to choose. \n \n` +
      `Tell us about your experience: application process, customer service, curriculum, take away, value-for-money?`,
  },
];
