import React from 'react';
import findIndex from 'lodash/findIndex';
import DropdownSelect from 'app/seedly-component-library/dropdown-select';

const makePlanOptions = (plans = [], onSelect) => {
  if (!plans) return null;

  const planOptions = plans.map(plan => {
    const { name, id } = plan;
    return {
      title: name,
      value: id,
      onClick: () => onSelect(id, name),
    };
  });

  planOptions.push({
    title: 'Others',
    value: 'others',
    onClick: () => onSelect(null, 'Others'),
  });

  return planOptions;
};

const PlanDropdown = props => {
  const { plans, onSelect, selectedPlanId } = props;
  if (!plans || plans.length === 0) return null;

  const planOptions = makePlanOptions(plans, onSelect);
  const selectedPlanIndex = findIndex(planOptions, { value: selectedPlanId });
  const selectedPlan =
    selectedPlanIndex === -1
      ? {
          title: 'Others',
          value: 'others',
          onClick: () => onSelect(null, 'Others'),
        }
      : planOptions[selectedPlanIndex];

  return (
    <DropdownSelect
      menuAlign="left"
      menuWidthClass="w-[200px]"
      variant="outline"
      items={planOptions}
      defaultSelectedItem={selectedPlan}
      handleSelectedItemchange={item => {
        item.selectedItem.onClick();
      }}
    />
  );
};

export default PlanDropdown;
