const handleLeavePage = e => {
  e.returnValue = '';
};

export const removeLeavePageEventListener = () => {
  window.removeEventListener('beforeunload', handleLeavePage);
};

export const addLeavePageEventListener = (isDirty, isEdit) => {
  if (isDirty && !isEdit) {
    window.addEventListener('beforeunload', handleLeavePage);
  } else {
    removeLeavePageEventListener();
  }
};
