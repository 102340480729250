import React, { useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'app/seedly-component-library';
import ConfirmationModal from 'app/components/post/ConfirmationModal';
import { closePostReviewModal as closePostModalDispatcher } from 'app/dispatcher/modalDispatcher';

function withModal(WrappedComponent) {
  const ModalHoc = props => {
    const contentRef = useRef(null);
    const setContentRef = useCallback(node => {
      contentRef.current = node;
    }, []);

    const dispatch = useDispatch();

    const [isFormDirty, setIsFormDirty] = useState(false);

    const isPostReviewModalOpen = useSelector(
      state => state.modal.isPostReviewModalOpen,
    );
    const isEdit = useSelector(state => state.review.form.isEdit);

    const handleCloseModal = () => {
      if (!isFormDirty) {
        return dispatch(closePostModalDispatcher());
      }

      ConfirmationModal({
        title: 'Close form?',
        content: 'Changes that you made may not be saved.',
        okText: 'Confirm',
        cancelText: 'Cancel',
      }).then(() => dispatch(closePostModalDispatcher()));
    };

    return (
      <Modal
        withCloseButton
        ariaHideApp={false}
        isOpen={isPostReviewModalOpen}
        shouldCloseOnOverlayClick={false}
        headerTitle={isEdit ? 'Edit Review' : 'Leave a Review'}
        onRequestClose={handleCloseModal}
        contentStyle={{ marginBottom: '50px' }}
        contentRef={setContentRef}
      >
        <WrappedComponent
          {...props}
          setIsFormDirty={() => setIsFormDirty(true)}
          handleScroll={(distanceFromTop = 0) => {
            const scrollOption = {
              top: distanceFromTop,
              behavior: 'smooth',
            };
            contentRef.current.scrollTo(scrollOption);
          }}
        />
      </Modal>
    );
  };

  return ModalHoc;
}

export default withModal;
