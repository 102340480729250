import { ruleRunner, run } from 'app/utils/formValidation';

const BodyValidation = value => {
  const errorMessage = moreChar =>
    `Your review is too short, ${moreChar} more characters required!`;

  const characters = value.replace(/\r?\n|\r/g, '').replace(/\[[^\]]*]/g, '')
    .length;

  if (characters < 100) {
    return () => errorMessage(100 - characters);
  }

  return null;
};

const RatingValidation = value => {
  const errorMessage = 'Give a rating.';

  if (!value) {
    return () => errorMessage;
  }

  return null;
};

const RatingBreakdownValidation = value => {
  const errorMessage = 'Give a rating breakdown.';
  let isValid = true;

  if (value) {
    isValid = Object.keys(value).reduce((acc, key) => {
      acc = acc && value[key] !== null;
      return acc;
    }, true);
  }
  if (!isValid) {
    return () => errorMessage;
  }

  return null;
};

const fieldValidations = [
  ruleRunner('rating', 'Rating', RatingValidation),
  ruleRunner('body', 'Review', BodyValidation),
  ruleRunner('ratingBreakdowns', 'Breakdown', RatingBreakdownValidation),
];

export const validator = values => {
  return run(values, fieldValidations);
};
