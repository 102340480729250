// form validation helpers
// implementation referenced from:
// https://spin.atomicobject.com/2016/10/05/form-validation-react/

const ruleRunner = (field, name, ...validations) => {
  return state => {
    // eslint-disable-next-line no-restricted-syntax
    for (const v of validations) {
      const errorMessageFunc = v(state[field], field, state);
      if (errorMessageFunc) {
        return {
          [field]: errorMessageFunc(name),
        };
      }
    }
  };
};

const run = (state, runners) => {
  return runners.reduce((memo, runner) => {
    return Object.assign(memo, runner(state));
  }, {});
};

export { ruleRunner, run };
