import styled from 'styled-components';

export const Section = styled.div<{ textColor: string }>`
  width: 100%;
  word-wrap: break-word;
  color: ${props => props.theme.colors[props.textColor]};

  a {
    color: ${props => props.theme.colors.seedlyBlue};
    text-decoration: underline;
  }

  ol,
  ul {
    margin: 16px 0;
  }

  li {
    padding: 0;
  }

  ol {
    li {
      list-style-type: decimal;
      list-style-position: outside;
      margin-left: 30px;
    }
  }

  ul {
    li {
      list-style-type: disc;
      list-style-position: outside;
      margin-left: 30px;
    }
  }

  img {
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const Anchor = styled.a`
  &:link,
  &:visited {
    color: ${props => props.theme.colors.seedlyBlue};
  }
  &:hover {
    text-decoration: underline;
  }
`;
