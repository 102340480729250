import React, { useState } from 'react';
import * as S from './styles';

const TextLabel = props => {
  const { onClick, label } = props;
  const [isActive, setActive] = useState(false);

  return (
    <S.LabelTag
      isActive={isActive}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={() => {
        onClick(label);
        setActive(false);
      }}
    >
      {label}
    </S.LabelTag>
  );
};

const TextLabelList = props => {
  const { labelList, onClick } = props;

  return (
    <>
      {labelList.map(label => {
        return (
          <TextLabel onClick={() => onClick(label)} key={label} label={label} />
        );
      })}
    </>
  );
};

export default TextLabelList;
