import React from 'react';
import noop from 'lodash/noop';
import { Rate } from 'app/seedly-component-library';
import * as S from './styles';

const ReviewBreakdownList = props => {
  const { breakdownList, onChange, ratingValue } = props;

  if (!breakdownList) return null;

  return breakdownList.map((breakdown, index) => {
    if (!breakdown) return null;
    const { name, description, id } = breakdown;
    const isLastItem = index === breakdownList.length - 1;
    return (
      <div
        key={id}
        style={{ marginBottom: isLastItem ? '60px' : '30px' }}
        data-testid="review-breakdown-list"
      >
        <ReviewBreakdown
          id={id}
          title={name}
          description={description}
          rating={ratingValue[id]}
          onChange={onChange}
        />
      </div>
    );
  });
};

// If is 0, is marked as not sure
// Default value is null
const ReviewBreakdown = props => {
  const { title, description, onChange, id, rating } = props;

  return (
    <S.CustomRatingSection>
      <S.CustomRatingTitle>{title}</S.CustomRatingTitle>
      {description && <S.ReviewDescription>{description}</S.ReviewDescription>}
      <S.RatingWrapper>
        <Rate
          size={36}
          defaultValue={rating === null ? 0 : rating}
          value={rating === null ? 0 : rating}
          onChange={r => {
            onChange(r, id);
          }}
          allowHalf={false}
          onHoverChange={noop}
        />
        <S.NotSureButton
          onClick={() => {
            onChange(0, id);
          }}
          isSelected={rating === 0}
          data-testid="button-not-sure"
        >
          Not Sure
        </S.NotSureButton>
      </S.RatingWrapper>
    </S.CustomRatingSection>
  );
};

export default ReviewBreakdownList;
