import get from 'lodash/get';
import analytics from 'app/utils/analytics';
import { LOGIN_MODAL, TYPES } from 'app/constants/login';

export const handlePostReview = (props, values, formikBag) => {
  const {
    productId,
    postReview,
    updateReview,
    product,
    productById,
    isSignedIn,
    onFailure,
    onSuccess,
    isEdit,
    review,
    openLoginModal,
  } = props;

  const { rating, body: text, planId, ratingBreakdowns } = values;
  const formValues = {
    item_id: productId,
    rating,
    text,
  };

  if (planId) {
    formValues.plan_id = planId;
  }

  if (ratingBreakdowns) {
    const ratingFormValue = [];
    Object.keys(ratingBreakdowns).forEach(key => {
      const ratingBreakdown = ratingBreakdowns[key];
      if (ratingBreakdown !== undefined && ratingBreakdown !== null) {
        ratingFormValue.push({ id: key, rating: ratingBreakdown });
      }
    });
    if (ratingFormValue.length > 0) {
      formValues.rating_breakdowns = [...ratingFormValue];
    }
  }

  if (isEdit) {
    updateReview(
      {
        formValues,
        reviewId: review.id,
      },
      {
        onSuccess,
        onFailure: response => {
          onFailure(response);
          formikBag.setSubmitting(false);
        },
      },
    );
    return;
  }

  if (!isSignedIn) {
    sessionStorage.setItem('new-review', JSON.stringify(formValues));
    return openLoginModal(LOGIN_MODAL[TYPES.CREATE_REVIEW]);
  }

  postReview(
    { formValues },
    {
      onSuccess: () => {
        onSuccess();
        const currentProduct = product || productById[productId];
        if (currentProduct) {
          const name = get(currentProduct, 'name', '');
          analytics.trackFacebookReviewSubmit(name, rating);
        }
      },
      onFailure: response => {
        onFailure(response);
        formikBag.setSubmitting(false);
      },
    },
  );
};
