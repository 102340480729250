import React from 'react';
import styled from 'styled-components';
import { Colors } from 'app/seedly-component-library';
import { reviewGuidelineImage } from 'theme';

const COMMUNITY_GUIDELINES_LINK =
  'https://seedlyhelp.zendesk.com/hc/en-us/categories/360000053801-Seedly-Community-Guidelines';

const Wrapper = styled.div`
  margin-bottom: 20px;
  font-size: 12px;
  border-radius: 8px;
  background-color: #ebf1fc;
  padding: 12px 18px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #40485a;
`;

const WarningIcon = styled.img`
  width: 26px;
  height: 26px;
  margin: 0 8px 4px -4px;
`;

const ReadMore = styled.a`
  margin-left: 20px;
  width: 120px;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ReviewGuideLine = () => (
  <Wrapper>
    <Content>
      <TextContent>
        <WarningIcon src={reviewGuidelineImage} />
        <p style={{ marginLeft: '5px' }}>
          Please do not leave your referral codes, phone number or links!
        </p>
      </TextContent>
      <ReadMore
        href={COMMUNITY_GUIDELINES_LINK}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: Colors.neutral5 }}
      >
        Read More
      </ReadMore>
    </Content>
  </Wrapper>
);

export default ReviewGuideLine;
