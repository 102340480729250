import React from 'react';
import RcRate from 'rc-rate';
import styled from 'styled-components';
import colors from '../themes/colors';

import 'rc-rate/assets/index.css';

interface StarProps {
  fillColor?: string;
  size?: number;
  color?: string;
}

const Star = ({ color = 'currentColor', size = 24, ...rest }: StarProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
    </svg>
  );
};

const StyledRate = styled(RcRate)<{
  size: number;
  starMargin: string;
  color: string;
}>`
  &.rc-rate {
    font-size: ${props => props.size}px;
    margin-right: 0px;
  }

  .rc-rate-star {
    margin-right: 0px;
    margin: ${props => props.starMargin};
  }

  .rc-rate-star-half .rc-rate-star-first,
  .rc-rate-star-full .rc-rate-star-second {
    color: ${props => props.color};
  }

  .rc-rate-star-half:hover .rc-rate-star-first,
  .rc-rate-star-full:hover .rc-rate-star-second {
    color: ${props => props.color};
  }
`;

interface RateProps {
  size?: number;
  color?: keyof typeof colors;
  starMargin: string;
  defaultValue?: number;
  disabled?: boolean;
}

// reference from https://github.com/react-component/rate
const Rate = (props: RateProps) => {
  const {
    size = 18,
    color = colors.green5,
    starMargin = null,
    defaultValue,
    disabled,
    // otherProps here is props for original component
    ...otherProps
  } = props;

  return (
    <StyledRate
      size={size}
      color={color}
      starMargin={starMargin}
      character={<Star size={size} />}
      allowHalf
      defaultValue={defaultValue}
      disabled={disabled}
      {...otherProps}
    />
  );
};

export default Rate;
