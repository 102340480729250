import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  removeLeavePageEventListener,
  addLeavePageEventListener,
} from 'app/utils/jsWarningPrompt';
import { imageUrl } from 'theme';
import get from 'lodash/get';
import curry from 'lodash/curry';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { Rate, Button, Markdown } from 'app/seedly-component-library';

import { postReview, updateReview } from 'app/dispatcher/reviewDispatcher';
import {
  openLoginModal,
  closePostReviewModal,
} from 'app/dispatcher/modalDispatcher';
import { getProduct } from 'app/dispatcher/productDispatcher';
import { REVIEW_FORM_PLACEHOLDER } from 'app/constants/reviewFormPlaceholder';
import { useFormik } from 'formik';
import { handlePostReview } from './handleSubmit';

import { validator } from './validation';
import WordPromptList from './WordPromptList';
import ReviewBreakdownList from './ReviewBreakdownList';
import PlanDropdown from './ReviewPlanDropdown';
import ReviewGuideline from './ReviewGuideline';
import * as S from './styles';
import withModal from './withModal';

const ratingMessages = {
  1: "It was terrible, I'll recommend everyone to avoid it.",
  2: "It was bad, I won't use it again.",
  3: 'It was average, I might use it agin.',
  4: 'It was good, it is still my first choice next time!',
  5: "It was perfect & I'll recommend everyone to use it!",
};

const TO_PLAN_DROPDOWN = 250;
const TO_PLAN_BREAKDOWN = 400;

const ErrorModal = () => {
  return (
    <S.ErrorContainer>
      <img height={205} width={125} src={imageUrl.errorAlmond} alt="" />
      <S.ErrorText>Uh oh! Something went wrong.</S.ErrorText>
      <S.ErrorText>Please come back later</S.ErrorText>
    </S.ErrorContainer>
  );
};

const PostReviewForm = props => {
  const {
    isEdit,
    review,
    setIsFormDirty,
    productId,
    productById,
    product,
    categorySlug,
    handleScroll,
  } = props;
  const dispatch = useDispatch();

  const initialRatingMessage = isEdit
    ? ratingMessages[JSON.stringify(review.rating)]
    : '';

  // If edit, use product in review item.
  // If is in comparison page, use product in meta.
  const reviewProduct = isEdit
    ? review.product
    : productById[productId] || product;
  const [showErrors, setShowErrors] = useState(false);
  const [apiError, setApiError] = useState({});
  const [isTextAreaFocus, setTextAreaFocus] = useState(false);
  const [ratingMessage, setRatingMessage] = useState(initialRatingMessage);
  const reviewTextAreaEl = useRef(null);

  if (!reviewProduct) return <ErrorModal />;

  const { plans, name, breakdowns, wordPrompts } = reviewProduct;
  const havePrompts = wordPrompts && wordPrompts.length > 0;
  const havePlans = plans && plans.length > 0;
  const haveBreakdown = breakdowns && breakdowns.length > 0;

  let ratingBreakdownById;
  if (haveBreakdown) {
    ratingBreakdownById = breakdowns.reduce((acc, item) => {
      const ratingVal = isEdit
        ? get(review, `ratingBreakdowns[${item.id}]`, null)
        : null;
      acc[item.id] = ratingVal;
      return acc;
    }, {});
  }

  const initialValues = {
    rating: isEdit ? review.rating : 0,
    body: isEdit ? review.reviewText : '',
    // eslint-disable-next-line no-nested-ternary
    planId: isEdit
      ? get(review, 'planId', null)
      : havePlans
      ? plans[0].id
      : null,
    ratingBreakdowns: ratingBreakdownById,
  };
  const onFailure = response => {
    setApiError(response);
    setShowErrors(true);
  };
  const onSuccess = () => {
    dispatch(closePostReviewModal());
  };

  const reviewProps = { onFailure, onSuccess, ...props };
  const handleSubmit = curry(handlePostReview)(reviewProps);

  const formik = useFormik({
    initialValues,
    validate: validator,
    onSubmit: handleSubmit,
  });

  const { values, errors, dirty: isDirty, isSubmitting } = formik;

  const updateRatingMessage = rating => {
    const rate = isNil(rating) ? values.rating : rating;
    const ratingMessageObj = ratingMessages[JSON.stringify(rate)];
    setRatingMessage(ratingMessageObj);
  };

  if (isEmpty(errors) && isEmpty(apiError) && showErrors) {
    setShowErrors(false);
  }
  if (!isEmpty(errors) && !isEmpty(apiError)) {
    setApiError({});
  }

  useEffect(() => {
    addLeavePageEventListener(isDirty, isEdit);
    setIsFormDirty(isDirty);
    return () => {
      removeLeavePageEventListener();
    };
  }, [isDirty]);

  const placeholder = find(REVIEW_FORM_PLACEHOLDER, ['uid', categorySlug]);
  const showTextError = errors.body && showErrors;
  const postInvalid =
    errors.rating || errors.ratingBreakdowns || values.body.length === 0;

  return (
    <div>
      <S.RatingTitle>
        How was your overall experience with {name}?
      </S.RatingTitle>
      <S.RatingSection data-testid="rating-overall-experience">
        <Rate
          size={42}
          defaultValue={values.rating}
          onChange={rating => {
            const scrollTo = TO_PLAN_DROPDOWN;
            formik.setFieldValue('rating', rating);
            updateRatingMessage(rating);
            handleScroll(scrollTo);
          }}
          allowHalf={false}
          onHoverChange={rating => updateRatingMessage(rating)}
        />
        <S.RatingMessage>{ratingMessage}</S.RatingMessage>
      </S.RatingSection>
      {havePlans && (
        <>
          <S.Divider />
          <S.RatingTitle>What product did you purchase?</S.RatingTitle>
          <PlanDropdown
            plans={plans}
            selectedPlanId={values.planId}
            onSelect={planId => {
              const scrollTo = TO_PLAN_BREAKDOWN;
              formik.setFieldValue('planId', planId);
              handleScroll(scrollTo);
            }}
          />
        </>
      )}
      {haveBreakdown && (
        <>
          <S.Divider />
          <S.RatingTitle>
            Help the community by letting them know your rating for these:
          </S.RatingTitle>
          <ReviewBreakdownList
            breakdownList={breakdowns}
            ratingValue={values.ratingBreakdowns}
            onChange={(rating, id) => {
              const newValue = { ...values.ratingBreakdowns, [id]: rating };
              formik.setFieldValue('ratingBreakdowns', newValue);

              // Focus text if all rating breakdown selected
              const focusText = Object.keys(newValue).reduce((acc, key) => {
                acc = acc && newValue[key] !== null;
                return acc;
              }, true);

              if (focusText && !isTextAreaFocus) {
                reviewTextAreaEl.current.focus();
                setTextAreaFocus(true);
              }
            }}
          />
        </>
      )}
      <S.Divider />
      <S.RatingTitle>Share your experience with us!</S.RatingTitle>
      <ReviewGuideline />
      {havePrompts && (
        <S.LabelSection>
          <WordPromptList
            onClick={label => {
              if (values.body.length === 0) {
                formik.setFieldValue('body', `${values.body}[${label}]\n`);
              } else {
                formik.setFieldValue('body', `${values.body}\n[${label}]\n`);
              }
              reviewTextAreaEl.current.focus();
            }}
            labelList={wordPrompts}
          />
        </S.LabelSection>
      )}
      <S.TextArea
        data-testid="textarea-review"
        placeholder={
          placeholder
            ? placeholder.description
            : `Your review helps other users decide which product(s) to choose. \n \n` +
              `Tell us about your experience: application process, customer service, value-for-money, & any pros and cons?`
        }
        ref={reviewTextAreaEl}
        value={values.body}
        showError={showTextError}
        onChange={e => formik.setFieldValue('body', e.target.value)}
      />
      {showErrors && (
        <S.FormError>
          {errors.rating || errors.body || errors.ratingBreakdowns || (
            <Markdown source={apiError.message || ''} />
          )}
        </S.FormError>
      )}
      <S.Footer>
        <Button
          data-testid="button-post"
          disabled={postInvalid || isSubmitting}
          onClick={() => {
            if (isSubmitting) return;
            setShowErrors(true);
            formik.handleSubmit();
          }}
        >
          {isEdit ? 'Save' : 'Post'}
        </Button>
      </S.Footer>
    </div>
  );
};

const mapStateToProps = state => ({
  isSignedIn: get(state.auth.session, 'isSignedIn', false),
  productId: state.review.form.productId,
  productById: state.product.byId,
  product: state.review.meta.product,
  categorySlug: state.review.form.categorySlug,
  review: state.review.form.review,
  isEdit: state.review.form.isEdit,
});

const mapDispatchToProps = {
  postReview,
  updateReview,
  openLoginModal,
  getProduct,
};

export default withModal(
  connect(mapStateToProps, mapDispatchToProps)(PostReviewForm),
);
