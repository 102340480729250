// Normalize analytics url, ensure that it starts with slash, and ends without slash.
const normalize = (url = '') => {
  url = (url || '').trim();
  if (!url.startsWith('/')) url = `/${url}`;
  if (url.endsWith('/')) url = url.substring(0, url.length - 1);
  return url;
};

const trackEvent = ({ eventCategory, eventAction, eventLabel = '' }) => {
  window.ga('send', {
    hitType: 'event',
    eventCategory,
    eventAction,
    eventLabel: eventLabel || 'UNDEFINED',
  });
};

// Outbound can be from
// 1. Plan - Banner
// 2. Plan - Header
// 3. Product - Header
// 4. Product - Plan Carousel
// 5. SAC
// 6. Pdt Listing
// 7. Comparison Listing

const trackOutbound = ({ url, from = '' }) => {
  if (!window || !window.ga) return;

  trackEvent({
    eventCategory: 'Outbound',
    eventAction: `Outbound from ${from}`,
    eventLabel: normalize(url),
  });
};

const trackNavbarItemClick = ({ url, subCategoryName }) => {
  if (!window || !window.ga) return;

  trackEvent({
    eventAction: subCategoryName,
    eventCategory: 'Nav Bar',
    eventLabel: normalize(url), // Page that user is directed to
  });
};

const trackFacebookReviewSubmit = ({ productName, rating }) => {
  if (!window || !window.fbq) return;

  window.fbq('trackCustom', 'SubmitProductReview', {
    product_name: productName,
    rating,
  });
};

const trackApplyButton = ({
  productName,
  productCategory,
  productPrice,
  id,
}) => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'product_click_apply',
    component_type: 'product-card',
    component_title: 'CTA_Apply Now',
    action: 'click',
    items: [
      {
        item_id: id,
        item_name: productName,
        item_category: productCategory,
        price: productPrice,
        currency: 'SGD',
      },
    ],
  });
};

const trackWriteReviewButton = ({ productName, productCategory, id }) => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'product_interaction',
    component_type: 'card',
    component_title: 'Card Summary_Write Review',
    action: 'click',
    items: [
      {
        item_id: id,
        item_name: productName,
        item_category: productCategory,
      },
    ],
  });
};

const trackViewMoreButton = ({ productName, productCategory, id }) => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'product_interaction',
    component_type: 'product-card',
    component_title: 'CTA_View More',
    action: 'click',
    items: [
      {
        item_id: id,
        item_name: productName,
        item_category: productCategory,
      },
    ],
  });
};

const trackProductTitle = ({ productName, productCategory, id }) => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'product_interaction',
    component_type: 'product-card',
    component_title: 'Title_Product Title',
    action: 'click',
    items: [
      {
        item_id: id,
        item_name: productName,
        item_category: productCategory,
      },
    ],
  });
};

const trackPlanApplyButton = ({
  productName,
  productCategory,
  productPrice,
  id,
}) => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'product_click_apply',
    component_type: 'card',
    component_title: 'Key Features Plans_Apply Now',
    action: 'click',
    items: [
      {
        item_id: id,
        item_name: productName,
        item_category: productCategory,
        price: productPrice,
        currency: 'SGD',
      },
    ],
  });
};

const trackProductApplyButton = ({ productName, productCategory, id }) => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'product_click_apply',
    component_type: 'card',
    component_title: 'Card Summary_Visit Site',
    action: 'click',
    items: [
      {
        item_id: id,
        item_name: productName,
        item_category: productCategory,
      },
    ],
  });
};

const trackSeeMoreOfferButton = () => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'navigate',
    component_type: 'card',
    component_title: 'Card_See All Offers',
    action: 'click',
  });
};

const trackClickProductBreadcrumb = ({ title }) => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'navigate',
    component_type: 'breadcrumb',
    component_title: title,
    action: 'click',
  });
};

const trackMenuItemClick = ({ title, menuItemName }) => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'navigate',
    component_type: 'top-nav',
    component_title: `${title}_${menuItemName}`,
    action: 'click',
  });
};

const trackSortingClick = ({ key, value }) => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'filter_action',
    component_type: 'dropdown',
    component_title: `Expand Overlay_${key}`,
    action: 'click',
    filter_key: key,
    filter_value: value,
  });
};

const trackSortingChange = ({ key, value }) => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'filter_action',
    component_type: 'dropdown',
    component_title: 'Sort',
    action: 'select',
    filter_key: key,
    filter_value: value,
  });
};

const trackCategoryFilterClick = ({ key, value }) => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'filter_action',
    component_type: 'sidebar',
    component_title: `Apply_${value}`,
    action: 'click',
    filter_key: key,
    filter_value: value,
  });
};

const trackFilterApplied = ({ key, value, otherFilters }) => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'filter_action',
    component_type: 'sidebar',
    component_title: `Apply_${value}`,
    action: 'click',
    filter_key: key,
    filter_value: value,
    filter_applied: otherFilters.map(f => ({
      [f.key]: f.value,
    })),
  });
};
const trackResetFilter = () => {
  if (!window) return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'filter_action',
    component_type: 'overlay',
    component_title: 'Deselect All',
    action: 'click',
  });
};

export default {
  trackOutbound,
  trackNavbarItemClick,
  trackFacebookReviewSubmit,
  trackApplyButton,
  trackViewMoreButton,
  trackPlanApplyButton,
  trackProductApplyButton,
  trackSeeMoreOfferButton,
  trackProductTitle,
  trackWriteReviewButton,
  trackClickProductBreadcrumb,
  trackMenuItemClick,
  trackSortingClick,
  trackSortingChange,
  trackFilterApplied,
  trackCategoryFilterClick,
  trackResetFilter,
};
